import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { Layout } from '@msanvarov/core-components';
import React, { useState } from 'react';
import ResponsiveIframe from '../../../libs/core-components/src/lib/frame';

const AnimatedButton = ({ onClick, buttonText }) => {
  return (
    <motion.button
      whileHover={{
        scale: 1.1,
        boxShadow: '0px 0px 20px rgba(255, 255, 255, 0.5)',
      }}
      whileTap={{ scale: 0.9 }}
      onClick={onClick}
      style={{
        padding: '10px 20px',
        fontSize: '16px',
        background: 'linear-gradient(to right, #ff9966, #ff5e62)',
        color: 'white',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        outline: 'none',
        boxShadow: '0px 0px 20px rgba(255, 255, 255, 0.3)',
      }}
    >
      {buttonText}
    </motion.button>
  );
};

const BioPage = () => {
  const router = useRouter();
  const [showSVG, setShowSVG] = useState(false);
  const [buttonText, setButtonText] = useState('Return to homepage');

  const handleReturnHome = () => {
    // Change button text
    setButtonText('Navigating to homepage...');

    // Show the SVG and remove the iframe
    setShowSVG(true);
    
    // Redirect to the homepage after 4 seconds
    setTimeout(() => {
      router.push('/');
    }, 4000);
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <Layout title="404 Error Page">
        <h1 className="h1">404 - Page not found</h1>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '60px',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {!showSVG && (
            <div className="responsive-iframe-wrapper" style={{ width: '100%', height: '400px', overflow: 'hidden' }}>
              <ResponsiveIframe src="https://game-abhishek.netlify.app/chrome-dino/" title="GLOBE" />
            </div>
          )}
          
          {showSVG && (
            <img
              src="https://app.svgator.com/assets/svgator.webapp/log-in-girl.svg"
              alt="404 error"
              style={{
                width: '100%',
                maxWidth: '400px',
                height: 'auto',
                marginBottom: '20px',
              }}
            />
          )}
          
          <AnimatedButton onClick={handleReturnHome} buttonText={buttonText} />
        </div>

        <style jsx>{`
          @keyframes neon {
            0%,
            100% {
              text-shadow: 0 0 4px #fff, 0 0 10px #fff, 0 0 20px #ff00ff,
                0 0 30px #ff00ff, 0 0 40px #ff00ff, 0 0 50px #ff00ff,
                0 0 60px #ff00ff;
              color: #fff;
            }
            50% {
              text-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 10px #ff00ff,
                0 0 15px #ff00ff, 0 0 20px #ff00ff, 0 0 25px #ff00ff,
                0 0 30px #ff00ff;
              color: #ff00ff;
            }
          }

          @media (max-width: 600px) {
            .h1 {
              font-size: 2em;
            }
            button {
              padding: 8px 16px;
              font-size: 14px;
            }
          }

          @media (min-width: 601px) and (max-width: 1024px) {
            .h1 {
              font-size: 2.2em;
            }
            button {
              padding: 9px 18px;
              font-size: 15px;
            }
          }

          @media (min-width: 1025px) {
            .h1 {
              font-size: 2.5em;
            }
            button {
              padding: 10px 20px;
              font-size: 16px;
            }
          }
        `}</style>
      </Layout>
    </div>
  );
};

export default BioPage;
