import React from 'react';
import styles from './ResponsiveIframe.module.scss'; // Import the SCSS module

interface ResponsiveIframeProps {
    src: string;
    title: string;
    aspectRatio?: number; // Optional aspect ratio, default is 16:9
}

const ResponsiveIframe: React.FC<ResponsiveIframeProps> = ({ src, title, aspectRatio = 16 / 9 }) => {
    // Calculate padding-top based on the aspect ratio
    const paddingTop = `${(1 / aspectRatio) * 100}%`;

    return (
        <div
            className={styles.responsiveIframeContainer}
            style={{ paddingTop }}
        >
            <iframe
                src={src}
                title={title}
                allowFullScreen
            />
        </div>
    );
};

export default ResponsiveIframe;
